import React, { useEffect } from 'react';
import fbTrack from '../../components/_helpers';

function Spotify(props) {
  useEffect(() => {
    // https://open.spotify.com/track/3DGEPRckKThdoeTpSeivHy
    if (typeof window !== 'undefined') {
      let pathname = props.params.spotifyRedirectHome;

      if (pathname && pathname.length < 30) {
        window.location.href = `https://open.spotify.com/track/${pathname}`
        fbTrack('trackCustom', 'truevined-spotify-track-visit-redirect');
      }
      else {
        window.location.href = 'https://open.spotify.com/artist/256m8qrr1OX3cxhHpnsNJe'
        fbTrack('trackCustom', 'truevined-spotify-artist-visit-redirect');
      }
      
    }
  }
  , [])
  
  return (
    <div></div>
  )
}

export default Spotify